function resetAfterTimeout(form, message, timeout=1000) {
    setTimeout(() => {
        message.innerText = '';
        message.style.display = "none";
        form.classList.remove("success");
        form.classList.remove("error");
    }, timeout)
}

function init() {
    const form = document.querySelector("#Form_NewsletterForm");

    if (!form || form === undefined) return;

    const message = form.querySelector(".message");


    form.addEventListener("submit", (e) => {
        e.preventDefault();

        const formData = new FormData(form);

        fetch(form.action, {
            method: "POST",
            body: formData,
            headers: {
                "X-Requested-With": "XMLHttpRequest", // Optional: To let the server know it's an AJAX request
            },
        })
            .then((response) => response.json()) // Assuming the server returns JSON
            .then((data) => {
                if (data.success) {
                    // Handle success response
                    form.classList.add("success");
                    form.classList.remove("error");
                    message.innerText = data.message;
                    message.style.display = "block";

                    resetAfterTimeout(form, message)

                    form.reset(); // Clear the form inputs
                    console.log("Form submitted successfully:", data);
                } else {
                    // Handle error response
                    form.classList.add("error");
                    form.classList.remove("success");
                    message.innerText = data.message;
                    message.style.display = "block";

                    resetAfterTimeout(form, message)
                }
            })
            .catch((error) => {
                form.classList.add("error");
                form.classList.remove("success");
                message.innerText = data.message;

                resetAfterTimeout(form, message)

                message.style.display = "block";
                console.error("Error submitting form:", error);
            });
    });
}

init();
